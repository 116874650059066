<template>
  <div class="user-files">
    <FeedTitle :title="$user.name" :subtitle="$locale['manage_your_files']" />
    <UserAdminFilesHeader class="user-files-header" :total="total" />
    <Spacer />
    <SingleCard tcenter v-if="!total">
      <div>{{ $locale["not_files_found"] }}</div>
    </SingleCard>
    <div class="user-files-list">
      <CreatorFileCard v-for="(file, index) in files" :key="index" :file="file" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    UserAdminFilesHeader: () => import("./UserAdminFilesHeader.vue"),
  },
  methods: {
    getFiles: async function() {
      this.isLoading(true);
      try {
        const req = await this.$http.get(`${this.$apiHost}user/files`);
        this.$store.state.user.files = req.data;
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
  },
  mounted: function() {
    this.getFiles();
  },
  computed: {
    files: function() {
      return this.$store.state.user.files.items;
    },
    total: function() {
      return this.$store.state.user.files.total;
    },
  },
};
</script>

<style lang="scss">
$cardHeight: 150px;
.user-files {
  margin: $mpadding 0;
  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $mpadding/2;
  }
  &-file {
    overflow: hidden;
    min-height: 100%;
    position: relative;
    padding: 0;
    @include Transition(0.25s);
    user-select: none;
  }
  &-file:hover {
    @include Shadow($mpadding, rgba(234, 0, 0, 0.5));
  }
  &-file .cover {
    height: $cardHeight;
    background-image: var(--cover);
    background-size: cover;
    background-position: center;
  }
  &-file .name {
    text-align: center;
    padding: $mpadding/2;
    @include Ellipsis();
    display: grid;
    max-width: calc(100% - #{$mpadding});
    margin: 0 auto;
  }
  &-file .options {
    position: absolute;
    top: 0;
    width: 100%;
    justify-content: flex-end;
    padding: $mpadding/2;
    display: none;
  }
  &-file .options-button {
    width: $mpadding * 2;
    height: $mpadding * 2;
  }
  &-file:hover .options {
    display: flex;
  }
  &-file.__toDelete {
    max-width: 256px;
  }
  @include screen($tablet_width) {
    &-list {
      grid-gap: $mpadding;
    }
  }
}
.creatorFileCard {
  min-height: $cardHeight;
}
</style>
